import { createApp } from 'vue'
import {post} from './lib/utils'
import App from './App.vue'
import router from './router'

const app = createApp(App)
app.config.globalProperties.$post = post
app.config.globalProperties.$cdn = '/cdn'
app.use(router)

app.mount('#app')

