import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index/Index.vue'

const routerHistory = createWebHistory('/top')

const routes = [
	{
		path: '/',
    name: 'Root',
    component: Index,
	},
	{
		path: '/index',
    name: 'Index',
    component: Index,
	},
]

const router = createRouter({
	history: routerHistory,
  routes,
	scrollBehavior () {
		return {x: 0, y: 0}
	},
})

export default router

