<template>
<div id="app">
	<router-view />
</div>
</template>

<script>
</script>

<style src="./style.css" />
