<div class="index">

	<div class="header">
		<p>PWA Store</p>
	</div><!--header-->

	<ul>
		<li v-for="(l,i) in list" :key="i">
			<Card :l="l" />
		</li>
	</ul>

	<!--div class="footer">
		<a href="/developer/index">アプリ管理サイトへ</a>
	</div-->

	<Footer />

</div>
