<template>
	<div class="footer">
		<div class="menu">
			<a :href="$cdn + '/terms.html'" target="_blank">利用規約</a>
			<a href="https://www.sound-f.jp/" target="_blank">Sound-FinTechのサイト</a>
		</div>
		<p class="copy">&copy; Sound-FinTech Co.,Ltd.</p>
	</div><!--footer-->
</template>

<style scoped>
.footer{
	margin-top: 40px;
	padding: 40px 0;
}
.menu{
	max-width: 1024px;
	margin: auto;
}
.footer .menu>a{
	display: block;
	float: left;
	margin: 20px;
	color: var(--col-main);
}
.footer .menu>a:hover{
	text-decoration: underline;
}
.footer .copy{
	text-align: center;
	font-size: 12px;
}
</style>

<script>
</script>
