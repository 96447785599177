import Footer from '../../components/Footer.vue'
import Card from './Card.vue'

export default {
	data: function(){
		return {
			isReady: false,
			list: [],
		}
	},

	mounted: async function(){
		let res = await this.$post('/webapi/apps/list')
		console.log(res)
		if(res.code != 0){
			alert(res.message)
			return false
		}
		sessionStorage.setItem('appList', JSON.stringify(res.list))
		this.list = res.list
	},

	components: {
		Footer,
		Card,
	},

}
