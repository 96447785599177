<template>
<div class="wrapper" @click="goToDetail">
	<img :src="l.icon_url">
	<p>{{l.name}}</p>
</div>
</template>

<style scoped>
.wrapper{
	width: 226px;
	height: 200px;
	background-color: white;
	border: 1px solid #ddd;
	border-radius: 10px;
	text-align: center;
	padding: 10px;
	overflow: hidden;
	cursor: pointer;
}
img{
	height: 120px;
	width: 120px;
	background-color: #eee;
	border-radius: 20px;
}
p{
	display: block;
	margin-top: 10px;
	font-size: 18px;
	line-height: 24px;
}
@media screen and (max-width: 400px){
.wrapper{
	width: 100%;
	text-align: left;
}
img{
	float: left;
	margin-right: 20px;
}
}
</style>

<script>
export default {
	props: {
		l: {type: Object, default: () => { return {url: '', imgsrc: '', name: ''} }},
	},
	methods: {
		goToDetail: function(){
			location.href = '/detail/' + this.l.user_code + '/' + this.l.app_code + '.html'
		},
	},
}
</script>
